/* eslint-disable no-unused-vars */
import Auth from './auth';

const auth = new Auth('googleLoginButton');

auth.setListener('SIGNIN', async (token) => {
  const { default: initSeats } = await import(/* webpackChunkName: 'seats' */ './seats');
  initSeats(token, () => {
    auth.signOut();
  });
});

auth.setListener('SIGNOUT', async (token) => {
  window.location.reload();
});

auth.start();
