class Auth {
  constructor(loginButtonId) {
    this.loginButton = document.getElementById(loginButtonId);
    this.listeners = new Map();
  }

  start() {
    window.onGoogleLibraryLoad = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.OAUTH_CLIENT_ID,
        callback: (response) => {
          this.onGoogleSignIn(response);
        },
        auto_select: true,
        use_fedcm_for_prompt: true,
      });
      window.google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          window.google.accounts.id.renderButton(
            this.loginButton,
            {
              type: 'standard',
              theme: 'outline',
              size: 'large',
              width: 400,
            },
          );
        }
      });
    };
  }

  setListener(key, listener) {
    this.listeners.set(key, listener);
  }

  onGoogleSignIn(credentialResponse) {
    const { clientId, credential } = credentialResponse;
    // FedCM에서는 clientId 값을 주지 않는다.
    // 확인 결과, 다른 도메인으로 로그인하면 앞단에서 에러가 발생하여 여기로 오지 않으므로
    // 아래 client ID 체크 과정을 진행하지 않는다.
    const compatibleForFedCM = true;
    if (!compatibleForFedCM && clientId !== process.env.OAUTH_CLIENT_ID) {
      throw Error('Invalid client id');
    }

    const listener = this.listeners.get('SIGNIN');
    if (listener) {
      listener(credential);
    }
  }

  signOut() {
    window.google.accounts.id.disableAutoSelect();
    const listener = this.listeners.get('SIGNOUT');
    if (listener) {
      listener();
    }
  }
}

export default Auth;
